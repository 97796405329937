<template>
    <div>
        <h1
        class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white uji"
        style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
      >
        <h6 class="h2 text-white d-inline-block mb-0">
          <i class="fa fa-battery-quarter"></i> Tambah Isian Laporan Tidak Normal
        </h6>
      </h1>
      
      <base-header class="pb-6">
        <div class="card bg-white border-0">
              <div class="p-4">
                <h2 class="text-justify">
                  LAPORAN KONDISI TIDAK NORMAL ATAU KONDISI DARURAT
                </h2>
                <p class="mb-2 text-justify">
                  Fitur ini bertujuan melaporkan secara cepat KONDISI TIDAK
                  NORMAL dan KONDISI DARURAT peralatan SPARING. Penanggung jawab
                  usaha dan/atau kegiatan mendokumentasikan dan melaporkan
                  kondisi tidak normal dalam jangka waktu 3x24 jam setelah
                  terjadinya kondisi tidak normal kepada Bupati/Walikota dengan
                  tembusan DLH Provinsi dan KLHK. Kriteria kondisi tidak normal
                  dan kondisi darurat yaitu:
                </p>
                <ol type="A">
                  <li>
                    KONDISI TIDAK NORMAL
                    <ol>
                      <li>
                        Sesuai Pasal 8 ayat (3) PermenLHK No.93/2018 menyatakan
                        bahwa kondisi tidak normal meliputi:
                      </li>
                      <ol type="a">
                        <li>
                          Penghentian sementara dan penyalaan kembali operasi
                          produksi
                        </li>
                        <li>Kalibrasi peralatan</li>
                        <li>
                          Kondisi lain yang menyebabkan SPARING tidak dapat
                          digunakan secara optimal.
                        </li>
                      </ol>
                      <li>
                        Sementara itu kondisi lain yang menyebabkan peralatan
                        SPARING tidak dapat digunakan secara optimal
                        diantaranya:
                      </li>
                      <ol type="a">
                        <li>
                          Perbaikan IPAL (Perlu diperhatikan bahwa pada saat
                          startup dan shutdown IPAL akan ber-pengaruh terhadap
                          nilai kadar/kualitas air limbah)
                        </li>
                        <li>Perbaikan produksi</li>
                        <li>Gangguan supply listrik</li>
                        <li>
                          Khusus industri tambang pada saat curah hujan di atas
                          rata-rata tahunan
                        </li>
                      </ol>
                    </ol>
                  </li>
                  <li>
                    KONDISI DARURAT<br />
                    Kondisi darurat berupa:
                    <ol>
                      <li>
                        Bencana alam dapat berupa banjir, gempa bumi, gunung
                        meletus, gangguan binatang, dan lain sebagainya
                      </li>
                      <li>
                        Gangguan keamanan, dapat berupa pencurian, perusakan,
                        dan lain sebagainya.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
        </div>
      </base-header>
  
      <b-container fluid class="mt--6">
        <b-row>
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
              <b-row>
                <b-col lg="12" cols="12" style="display: flex; justify-content: flex-end">
                  <base-button size="md" type="primary" @click="backPage">Kembali</base-button>
                </b-col>
                <b-col lg="12" class="p-5">
                  <!-- INFORMASI -->
                  <h3>Informasi</h3>
                  <!-- Pilih Titik Penaatan -->
                  <div class="form-group row">
                    <label class="col-lg-12 col-form-label form-control-label">Pilih Titik Penaatan</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.pilihTitikPenaatan">
                          <el-option
                            v-for="(item, index) in listDataCompliencePoints"
                            :key="index"
                            :label="item.spot_name"
                            :value="item.id"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <!-- Pilih Kondisi -->
                  <div class="form-group row">
                    <label class="col-lg-12 col-form-label form-control-label">Pilih Kondisi</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.pilihKondisi">
                          <el-option
                            v-for="(item, index) in provideSelect.pilihKondisi"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <!-- =================== Kondisi Tidak Normal -->
                  <div class="form-group row mt--5" v-if="userInput.pilihKondisi == 'Kondisi Tidak Normal'">
                    <label class="col-lg-12 col-form-label form-control-label">Kondisi tidak normal</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.kondisiTidakNormal">
                          <el-option
                            v-for="(item, index) in provideSelect.kondisiTidakNormal"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  
                  <div class="form-group row mt--5" v-if="userInput.kondisiTidakNormal == 'Kondisi lain yang menyebabkan SPARING tidak dapat digunakan secara optimal' ">
                    <label class="col-lg-12 col-form-label form-control-label">Kondisi Lain yang Menyebabkan SPARING Tidak dapat Digunakan Secara Optimal</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.kondisiTidakNormalLainnya">
                          <el-option
                            v-for="(item, index) in provideSelect.kondisiTidakNormalLainnya"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <!-- =================== Kondisi Darurat -->
                  <div class="form-group row mt--5" v-if="userInput.pilihKondisi == 'Kondisi Darurat'">
                    <label class="col-lg-12 col-form-label form-control-label">Kondisi Darurat</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.kondisiDarurat">
                          <el-option
                            v-for="(item, index) in provideSelect.kondisiDarurat"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <!-- Tanggal Kejadian -->
                  <div class="form-group row mt--5">
                    <label  class="col-lg-12 col-form-label form-control-label">Tanggal Kejadian</label>
                    <div class="col-12">
                      <base-input>
                        <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="userInput.tanggalKejadian"/>
                      </base-input>
                    </div>
                  </div>

                  <!-- Tanggal Berakhir -->
                  <div class="form-group row mt--5">
                    <label  class="col-lg-12 col-form-label form-control-label">Tanggal Kejadian Berakhir</label>
                    <div class="col-12">
                      <base-input>
                        <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="userInput.tanggalKejadianBerakhir"/>
                      </base-input>
                    </div>
                  </div>

                  <!-- Lokasi -->
                  <div class="form-group row mt--5">
                    <label class="col-lg-12 col-form-label form-control-label">Lokasi</label>
                    <div class="col-lg-12">
                      <base-input
                        name="Pejabat Penerbit Izin"
                        v-model="userInput.lokasi"
                      ></base-input>
                    </div>
                  </div>



                  <!-- Fasilitas / Unit -->
                  <h3>Fasilitas / Unit</h3>
                  <!-- Pilih Fasilitas -->
                  <div class="form-group row">
                    <label class="col-lg-12 col-form-label form-control-label">Pilih Fasilitas / Unit</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.pilihFasilitas">
                          <el-option
                            v-for="(item, index) in provideSelect.pilihFasilitas"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <!-- ======================= Jika memilih Alat Sparing -->
                  <div v-if="userInput.pilihFasilitas == 'Alat Sparing' || userInput.pilihFasilitas == 'Alat Sparing & IPAL' ">
                    <div class="form-group row mt--5">
                      <label class="col-lg-12 col-form-label form-control-label">Merek Alat Sparing</label>
                      <div class="col-lg-12">
                        <base-input
                          name="Pejabat Penerbit Izin"
                          v-model="userInput.merekAlatSparing"
                        ></base-input>
                      </div>
                    </div>
  
                    <div class="form-group row mt--5">
                      <label  class="col-lg-12 col-form-label form-control-label">Tanggal Instalasi Alat Sparing</label>
                      <div class="col-12">
                        <base-input>
                          <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="userInput.tanggalInstalasiAlatSparing"/>
                        </base-input>
                      </div>
                    </div>
  
                    <div class="form-group row mt--5">
                      <label  class="col-lg-12 col-form-label form-control-label">Tanggal Dioperasikan Alat Sparing</label>
                      <div class="col-12">
                        <base-input>
                          <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="userInput.tanggalDioperasikanAlatSparing"/>
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <!-- ======================= Jika memilih Alat IPAL -->
                  <div  v-if="userInput.pilihFasilitas == 'Alat IPAL' || userInput.pilihFasilitas == 'Alat Sparing & IPAL'">
                    <div class="form-group row mt--5">
                      <label  class="col-lg-12 col-form-label form-control-label">Tanggal Instalasi Alat IPAL</label>
                      <div class="col-12">
                        <base-input>
                          <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="userInput.tanggalInstalasiAlatIPAL"/>
                        </base-input>
                      </div>
                    </div>
  
                    <div class="form-group row mt--5">
                      <label  class="col-lg-12 col-form-label form-control-label">Tanggal Dioperasikan Alat IPAL</label>
                      <div class="col-12">
                        <base-input>
                          <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="userInput.tanggalDioperasikanAlatIPAL"/>
                        </base-input>
                      </div>
                    </div>
  
                    <div class="form-group row mt--5">
                      <label class="col-lg-12 col-form-label form-control-label">Kapasitas Desain dan Operasional Alat IPAL</label>
                      <div class="col-lg-12">
                        <base-input
                          name="Pejabat Penerbit Izin"
                          v-model="userInput.kapasitasDesainOperasionalAlatIPAL"
                        ></base-input>
                      </div>
                    </div>
                  </div>

                  <!-- ======================= Jika memilih Lainnya -->
                  <div v-if="userInput.pilihFasilitas == 'Lainnya'">
                    <div class="form-group row mt--5" >
                      <label class="col-lg-12 col-form-label form-control-label">Isi Terkait Fasilitas/Unit Apa yang Menyebabkan Kondisi Tidak Normal/Kondisi Darurat</label>
                      <div class="col-lg-12">
                        <base-input
                          name="Pejabat Penerbit Izin"
                          v-model="userInput.lainnya"
                        ></base-input>
                      </div>
                    </div>
                  </div>


                  <!-- Main -->

                  <div class="form-group row mt--5">
                    <label class="col-lg-12 col-form-label form-control-label">Apakah Penyebab Kejadian Sudah Diatasi?</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.kejadianSudahDiatasi">
                          <el-option
                            v-for="(item, index) in ['Ya','Tidak']"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row mt--5">
                    <label class="col-lg-12 col-form-label form-control-label">Rencana Penanggulangan</label>
                    <div class="col-lg-12">
                      <base-input
                        name="Pejabat Penerbit Izin"
                        v-model="userInput.rencanaPenanggulangan"
                      ></base-input>
                    </div>
                  </div>

                  <div class="form-group row mt--5">
                    <label class="col-lg-12 col-form-label form-control-label">Apakah Ada Keluhan dari Masyarakat Karena Kejadian Ini?</label>
                    <div class="col-lg-12">
                      <base-input name="Satuan debit">
                        <el-select v-model="userInput.keluhanMasyarakat">
                          <el-option
                            v-for="(item, index) in ['Ya','Tidak']"
                            :key="index"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row">
                      <label class="col-12 col-form-label form-control-label">Lampirkan Prosedur Penanganan</label>
                      <div class="col-12">
                        <base-input>
                          <file-input
                            accept="application/pdf"
                            ref="report_upnormal_prosedur_penanganan_file"
                            @change="uploadFile('report_upnormal_prosedur_penanganan_file', 'report_upnormal_prosedur_penanganan_file')" />
                        </base-input>
                      </div>
                  </div>

                  <div class="form-group row">
                      <label class="col-12 col-form-label form-control-label">Surat Penyampaian Kondisi Tidak Normal atau Kondisi Darurat</label>
                      <div class="col-12">
                        <base-input>
                          <file-input
                            accept="application/pdf"
                            ref="report_upnormal_surat_penyampaian_file"
                            @change="uploadFile('report_upnormal_surat_penyampaian_file', 'report_upnormal_surat_penyampaian_file')" />
                        </base-input>
                      </div>
                  </div>

                  <div class="form-group row">
                      <label class="col-12 col-form-label form-control-label">Lampiran Bukti Logbook Harian Yang Ditanda Tangani Minimal Setara Manager Lingkungan</label>
                      <div class="col-12">
                        <base-input>
                          <file-input
                            accept="application/pdf"
                            ref="report_upnormal_bukti_logbook_file"
                            @change="uploadFile('report_upnormal_bukti_logbook_file', 'report_upnormal_bukti_logbook_file')" />
                        </base-input>
                      </div>
                  </div>

                  <div class="form-group row">
                      <label class="col-12 col-form-label form-control-label">Surat Pernyataan Kondisi Tidak Normal</label>
                      <div class="col-12">
                        <base-input>
                          <file-input
                            accept="application/pdf"
                            ref="report_upnormal_surat_pernyataan_file"
                            @change="uploadFile('report_upnormal_surat_pernyataan_file', 'report_upnormal_surat_pernyataan_file')" />
                        </base-input>
                      </div>
                  </div>

                  <div style="display: flex;justify-content: center;">
                    <button class="btn btn-primary w-50" @click="submit">KIRIM</button>
                  </div>

                </b-col>
              </b-row>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <!-- Loading Screen -->
    <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative;">
                <img src="img/brand/img-logo-color.png" alt="">
                <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    
    </div>
  </template>
  
  <script>
  import API from '../../../api/base_url'
  import FileInput from "@/components/Inputs/FileInput";
  import { Select, Option } from "element-ui";
  import BaseHeader from "@/components/BaseHeader";
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import Modal from "@/components/Modal.vue";
  
  export default {
  components: {
    BaseHeader,
    Modal,
    FileInput,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mounted() {
    this.getCompliencePoint()
    this.log_as =  localStorage.getItem('as')
  },
  data() {
    return {
      report_upnormal_prosedur_penanganan_file_id:"",
      report_upnormal_surat_penyampaian_file_id:"",
      report_upnormal_bukti_logbook_file_id:"",
      report_upnormal_surat_pernyataan_file_id:"",
      isLoading:false,
      listDataCompliencePoints:[],
      payloadForm : new FormData(),
      log_as:"",
      isLoading:false,
      listData: [],
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altFormat: "Y-m-d",
          altInput: false,
          dateFormat: "Y-m-d",
          mode: "single",
        },
        pilihKondisi: [
        'Kondisi Terkendala Internet',
        'Kondisi Tidak Normal',
        'Kondisi Darurat',
        'Kondisi Tidak Ada Aliran Air Limbah',
        ],
        pilihFasilitas: [
          'Alat Sparing',
          'Alat IPAL',
          'Alat Sparing & IPAL',
          'Lainnya'
        ],
        kondisiTidakNormal: [
          'Penghentian sementara dan penyalaan kembali Operasi Produksi',
          'Kalibrasi peralatan',
          'Kondisi lain yang menyebabkan SPARING tidak dapat digunakan secara optimal'
        ],
        kondisiDarurat: [
          'Bencana Alam, dapat berupa pencurian, perusakan, dan lain sebagainya',
          'Gangguan keamanan, dapat berupa pencurian, perusakan , dan lain sebagainya '
        ],
        kondisiTidakNormalLainnya: [
          'Perbaikan IPAL (Perlu Diperhatikan Bahwa pada Saat Startup dan Shutdown IPAL akan ber-pengaruh Terhadap Nilai Kadar/Kualitas Air Limbah)',
          'Perbaikan Produksi',
          'Gangguan Supply Listrik',
          'Khusus Industri Tambang pada Saat Curah Hujan diatas Rata-Rata Tahunan'
        ]
      },
      userInput: {
        pilihTitikPenaatan:"",
        pilihKondisi:"",
        kondisiTidakNormal:"",
        kondisiTidakNormalLainnya:"",
        kondisiDarurat:"",
        pilihFasilitas:"Lainnya",
        lokasi:"",
        tanggalKejadian:"",
        tanggalKejadianBerakhir:"",
        merekAlatSparing:"-",
        tanggalInstalasiAlatSparing: "",
        tanggalDioperasikanAlatSparing:"",
        tanggalInstalasiAlatIPAL: "",
        tanggalDioperasikanAlatIPAL:"",
        kejadianSudahDiatasi:"",
        rencanaPenanggulangan:"",
        keluhanMasyarakat:"",
        kapasitasDesainOperasionalAlatIPAL:"",
        lainnya:"-"
      },
    };
  },
  methods: {
      backPage() {
        this.$router.push("/laporan-kondisi-tidak-normal");
      },
      uploadFile(field){
      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.uploading = true
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data
          let value = data.id
          // this.$set(this.userInput, field, value);
          // console.log(`${data}:`, this.userInput[field]);
          this.uploading = false;

          if (field == 'report_upnormal_prosedur_penanganan_file') {
            this.report_upnormal_prosedur_penanganan_file_id = value
          }

          if (field == 'report_upnormal_surat_penyampaian_file') {
            this.report_upnormal_surat_penyampaian_file_id = value
          }

          if (field == 'report_upnormal_bukti_logbook_file') {
            this.report_upnormal_bukti_logbook_file_id = value
          }

          if (field == 'report_upnormal_surat_pernyataan_file') {
            this.report_upnormal_surat_pernyataan_file_id = value
          }
        })
        .catch((err) => {
          this.uploading = false;
          Swal.fire(
                  "Terjadi Kesalahan",
                  "Mohon upload ulang kembali",
                  "error"
          );
        });
      },
      getCompliencePoint() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`stations` , {headers})
        .then(({data:content}) => {
          this.listDataCompliencePoints = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      resetData() {
        this.userInput = {
          pilihTitikPenaatan:"",
          pilihKondisi:"",
          kondisiTidakNormal:"",
          kondisiTidakNormalLainnya:"",
          kondisiDarurat:"",
          pilihFasilitas:"Lainnya",
          lokasi:"",
          tanggalKejadian:"",
          tanggalKejadianBerakhir:"",
          merekAlatSparing:"-",
          tanggalInstalasiAlatSparing: "",
          tanggalDioperasikanAlatSparing:"",
          tanggalInstalasiAlatIPAL: "",
          tanggalDioperasikanAlatIPAL:"",
          kejadianSudahDiatasi:"",
          rencanaPenanggulangan:"",
          keluhanMasyarakat:"",
          kapasitasDesainOperasionalAlatIPAL:"",
          lainnya:"-"
        }
      },
      submit() {
        Swal.fire({
          title: "Konfimasi Tindakan",
          text: "Anda yakin ingin menambahkan data",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "KIRIM DATA",
          cancelButtonText: "BATAL"
        }).then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true
            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }

         
            // this.payloadForm.append('keterangan_lainnya',this.userInput.lainnya)
            // this.payloadForm.append('kondisi_darurat', this.userInput.kondisiDarurat)
         

            let payload = {
              fasilitas: this.userInput.pilihFasilitas,
              fasilitas_ipal: {
                kapasitas: this.userInput.kapasitasDesainOperasionalAlatIPAL,
                tanggal_instalasi: this.userInput.tanggalInstalasiAlatIPAL,
                tanggal_operasi: this.userInput.tanggalDioperasikanAlatIPAL
              },
              fasilitas_lain: "Level Non-Oily Tank Low sehingga proses ke effluent distop sampai level Non-Oily High dan proses ke effluent distart kembali",
              fasilitas_sparing: {
                merek: this.userInput.merekAlatSparing,
                tanggal_instalasi: this.userInput.tanggalInstalasiAlatSparing,
                tanggal_operasi: this.userInput.tanggalDioperasikanAlatSparing
              },
              kondisi: this.userInput.pilihKondisi,
              kondisi_detail: {
                end_date: this.userInput.tanggalKejadianBerakhir,
                location: this.userInput.lokasi,
                start_date: this.userInput.tanggalKejadian,
                status: this.userInput.kondisiTidakNormal,
                status_detail: this.userInput.kondisiTidakNormalLainnya
              },
              logbook_path_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
              penanganan_path_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
              penyampaian_path_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
              pernyataan_path_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
              station_id: this.userInput.pilihTitikPenaatan,
              status_penanganan: {
                keluhan_masyarakat: this.userInput.keluhanMasyarakat,
                rencana_penanggulangan: "Akan tetap dilaksanakan pemantauan harian secara langsung pada outlet EWTP/GHDC untuk memastikan kondisi aliran sesuai dengan pembacaan pada SPARING.",
                sudah_teratasi: this.userInput.kejadianSudahDiatasi
              }
            }
            
            
            API.post('upnormal-reports' , payload, {headers})
            .then(({data}) => {
              this.resetData()
              this.$router.push("/laporan-kondisi-tidak-normal");
              Swal.fire({title: "Berhasil Menambahkan", text: "",icon: "success"});
              })
              .catch((err) => {
                this.isLoading = false
                Swal.fire("Kirim data gagal", 'harap telah mengisi semua data atau periksa koneksi internet anda', "error");
              })
          }
        });
      },
    },
  };
  </script>
  